import { forwardRef } from "react";

import Typography from "@mui/material/Typography";
import classnames from "classnames";

import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { Icon } from "../Icon";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyOptionLabelClasses, StyledRoot } from "./styles";
import { OptionLabelProps } from "./types";

import { Tooltip } from "@/design-system/Tooltip/Tooltip";
import { Info } from "@/icons/outlined";

export const OptionLabel = forwardRef<HTMLDivElement, OptionLabelProps>(
  (
    {
      alignContent = "default",
      children,
      className,
      classes: externalClasses,
      vibe,
      disabled = false,
      errorMessage,
      helperText,
      label,
      size = "medium",
      tooltipText,
      fullWidth,
      "data-test": dataTest,
      style,
      onClick,
      inputId,
      labelSx,
    },
    ref
  ) => {
    const { isSmallScreen } = useMediaQuery();
    const classes = useMergeClasses(zeffyOptionLabelClasses, externalClasses);

    return (
      <StyledRoot
        style={style}
        className={classnames(
          classes.container,
          className,
          { [classes.fullWidth]: Boolean(fullWidth) },
          { [classes.clickable]: Boolean(onClick) }
        )}
        ref={ref}
        data-test={dataTest}
        onClick={onClick}
      >
        <div
          className={classnames(classes.contentSection, {
            [classes.fullWidth]: Boolean(fullWidth),
            [classes.contentSectionCentered]: alignContent === "center" || Boolean(tooltipText),
          })}
        >
          <div className={classnames(classes.children, { [classes.childrenSmall]: size === "small" })}>{children}</div>
          {label && (
            <div
              className={classnames(classes.labelSection, {
                [classes.fullWidth]: Boolean(fullWidth),
              })}
            >
              <Typography
                variant={size === "medium" ? "body1" : "body2"}
                className={classnames(classes.label, externalClasses?.label, {
                  [classes.labelForm]: vibe !== "neutral",
                  [classes.labelNeutral]: vibe === "neutral",
                  [classes.disabledLabel]: disabled,
                  [classes.fullWidth]: Boolean(fullWidth),
                })}
                display="inline"
                component="label"
                htmlFor={inputId}
                sx={labelSx}
              >
                {label}
              </Typography>
            </div>
          )}
          {tooltipText && (
            <Tooltip
              title={tooltipText}
              className={classnames(classes.tooltip, { [classes.tooltipSmall]: size === "small" })}
              arrow
              placement="top"
              enterTouchDelay={isSmallScreen ? 0 : undefined}
            >
              <Icon size="small" pointer>
                <Info />
              </Icon>
            </Tooltip>
          )}
        </div>
        {errorMessage ? (
          <Typography variant="caption" className={classes.error}>
            {errorMessage}
          </Typography>
        ) : helperText ? (
          <Typography variant="caption" className={classes.helperText}>
            {helperText}
          </Typography>
        ) : null}
      </StyledRoot>
    );
  }
);

OptionLabel.displayName = "OptionLabel";
