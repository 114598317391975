import {
  CSSProperties,
  forwardRef,
  FunctionComponent,
  MouseEventHandler,
  PropsWithChildren,
  ReactEventHandler,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type JSX,
} from "react";

import { SxProps, Theme } from "@mui/material";
import NextImage, { ImageLoaderProps, ImageProps as NextImageProps } from "next/image";

import { rgbDataURL } from "@/helpers/colors";

type ZeffyNextImageProps = Omit<NextImageProps, "loader"> & {
  nextImage: true;
  objectFit?: NonNullable<JSX.IntrinsicElements["img"]["style"]>["objectFit"] | undefined;
  resolution?: number;
  fallbackSrc?: string | null;
  handleError?: () => void;
};

type DefaultImageProps = {
  nextImage?: false;
  alt: string;
  src: string;
  height: number | string | undefined;
  width: number | string | undefined;
  id?: string;
  className?: string;
  style?: CSSProperties | undefined;
  onClick?: MouseEventHandler<HTMLImageElement> | undefined;
  onLoad?: ReactEventHandler<HTMLImageElement> | undefined;
  ref?: Ref<HTMLImageElement>;
  fallbackSrc?: string | null;
};

type ImageProps = (ZeffyNextImageProps | DefaultImageProps) & {
  "data-test"?: string;
  sx?: SxProps<Theme>;
};

export const isNextImage = (props: ImageProps): props is ZeffyNextImageProps => {
  return (props as ZeffyNextImageProps).nextImage === true;
};

const MAX_NEXT_IMAGE_WIDTH = 1920;

const Image: FunctionComponent<PropsWithChildren<ImageProps>> = forwardRef(
  (props: ImageProps, ref: Ref<HTMLImageElement>) => {
    const [imageSrc, setImageSrc] = useState(props.src);
    const handleError = useCallback(() => {
      if (props.fallbackSrc) {
        setImageSrc(props.fallbackSrc);
      }
    }, [props.fallbackSrc]);

    /**
     *  Update image src when props.src changes
     */
    useEffect(() => {
      setImageSrc(props.src);
    }, [props.src]);

    if (!isNextImage(props)) {
      const { alt, height, width, className, id, onClick, onLoad, sx } = props;

      return (
        <img
          id={id}
          alt={alt}
          src={imageSrc as string}
          height={height}
          style={{ ...props.style, ...(sx as object) }}
          className={className}
          width={width}
          onClick={onClick}
          onLoad={onLoad}
          onError={handleError}
          ref={ref}
          data-test={props["data-test"]}
        />
      );
    }

    const { src: _omit, sx, style, ...restProps } = props;
    return (
      <ZeffyNextImage
        src={imageSrc}
        {...restProps}
        ref={ref}
        handleError={handleError}
        style={{ ...style, ...(sx as object) }}
      />
    );
  }
);

Image.displayName = "Image";

export default Image;

const ZeffyNextImage = forwardRef(
  (props: ZeffyNextImageProps & { sx?: SxProps<Theme> }, ref: Ref<HTMLImageElement>) => {
    const {
      blurDataURL = rgbDataURL(211, 211, 211),
      height,
      objectFit = "contain",
      placeholder = "blur",
      priority = false,
      sizes,
      style,
      sx,
      width,
      handleError,
      nextImage: _nextImage,
      resolution,
      ...rest
    } = props;

    const currentSizes = sizes ?? (width ? `${width}px` : undefined);

    const imageLoader = useCallback(
      (imageLoaderProps: ImageLoaderProps) => {
        const cappedImageWidthToAvoidBigImagesOnIE11 =
          resolution ||
          (typeof width === "string"
            ? imageLoaderProps.width
            : Math.min(imageLoaderProps.width, width || 0, MAX_NEXT_IMAGE_WIDTH));

        if (imageLoaderProps.src.startsWith("https://res.cloudinary.com/")) {
          // Cloudinary code
          const params = [
            "f_auto",
            "c_limit",
            `w_${cappedImageWidthToAvoidBigImagesOnIE11}`,
            `q_${imageLoaderProps.quality || "auto"}`,
          ];
          const urlSections = imageLoaderProps.src.split("/upload/");
          return `${urlSections[0]}/upload/${params.join(",")}/${urlSections[1]}`;
        }
        return `${imageLoaderProps.src}?w=${cappedImageWidthToAvoidBigImagesOnIE11}&q=${imageLoaderProps.quality || 75}`;
      },
      [width, resolution]
    );

    const currentStyle = useMemo(
      () => ({
        ...style,
        ...(sx as object),
        objectFit,
      }),
      [objectFit, style, sx]
    );

    return (
      <NextImage
        {...rest}
        src={rest.src}
        alt={rest.alt}
        blurDataURL={blurDataURL}
        height={height}
        loader={imageLoader}
        placeholder={placeholder}
        priority={priority}
        sizes={currentSizes}
        style={currentStyle}
        width={width}
        ref={ref}
        onError={handleError}
      />
    );
  }
);

ZeffyNextImage.displayName = "ZeffyNextImage";
