import { useCallback } from "react";

import { IsoLocale } from "@simplyk/common";
import { useRouter } from "next/router";

export const useSetIsoLocale = () => {
  const router = useRouter();
  const setIsoLocale = useCallback(
    (locale: IsoLocale) => {
      // eslint-disable-next-line no-console
      console.log(`Redirecting ${router.asPath} to locale ${locale}`);
      router.replace(router.asPath, router.asPath, { locale });
    },
    [router]
  );

  return {
    setIsoLocale,
  };
};
