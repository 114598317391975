import { forwardRef, useCallback } from "react";

import { PopperProps } from "@mui/material/Popper";
import MuiTooltip from "@mui/material/Tooltip";
import classnames from "classnames";

import { ForwardRefComponentDS } from "../types";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyTooltipClasses, StyledPopper } from "./styles";
import { TooltipBaseClasses, TooltipProps } from "./types";

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      children,
      classes: externalClasses,
      vibe = "neutral",
      onClick,
      stopPropagation,
      title,
      "data-test": dataTest,
      PopperProps: externalPopperProps,
      ...rest
    },
    ref
  ) => {
    const classes = useMergeClasses(zeffyTooltipClasses, externalClasses);
    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        stopPropagation && event.stopPropagation();
        onClick?.(event);
      },
      [onClick, stopPropagation]
    );

    if (!title) {
      return <>{children}</>;
    }

    return (
      <MuiTooltip
        {...rest}
        title={title}
        PopperProps={
          {
            "data-test": dataTest,
            ...externalPopperProps,
          } as Partial<PopperProps>
        }
        enterTouchDelay={0}
        leaveTouchDelay={10000}
        onClick={handleClick}
        classes={{
          tooltip: classnames(classes.tooltip, classes[vibe]),
          arrow: classes.arrow,
        }}
        PopperComponent={StyledPopper}
        ref={ref}
      >
        {children}
      </MuiTooltip>
    );
  }
) as ForwardRefComponentDS<TooltipProps, unknown, TooltipBaseClasses>;
Tooltip.displayName = "Tooltip";
