export const isCanadianEINValid = (ein) => {
    if (!ein) {
        return true;
    }
    return /^\d{9}RR\d{4}$/.test(ein);
};
export const IsUSEINValid = (ein) => {
    if (!ein) {
        return true;
    }
    return /^\d{2}-\d{7}$/.test(ein) || /^\d{9}$/.test(ein);
};
export const isEINValid = (ein) => {
    return IsUSEINValid(ein) || isCanadianEINValid(ein);
};
export const formatEIN = (ein) => ein && /^\d{9}$/.test(ein) ? `${ein.slice(0, 2)}-${ein.slice(2)}` : ein;
