import randomColor from "randomcolor";

import { zeffyTheme } from "../theme/theme";

const colors = [
  zeffyTheme.palette.primary.main,
  zeffyTheme.palette.secondary.main,
  zeffyTheme.palette.tertiary.main,
  zeffyTheme.palette.error.main,
  zeffyTheme.palette.warning.main,
];

export const getPaletteRandomColor = () => {
  return colors[Math.floor(Math.random() * 10) % 5];
};
export interface RandomColorSetting {
  count: number;
  luminosity: "bright" | "light" | "dark" | "random";
}

export const getRandomColor = (color: string | undefined, randomColorSetting: RandomColorSetting) => {
  try {
    return randomColor({
      count: 10,
      luminosity: randomColorSetting.luminosity,
      hue: color,
    })?.[Math.floor(Math.random() * 100) % randomColorSetting.count];
  } catch {
    return randomColor({
      count: 10,
      luminosity: randomColorSetting.luminosity,
      hue: "monochrome",
    })?.[Math.floor(Math.random() * 100) % randomColorSetting.count];
  }
};

// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

export const rgbDataURL = (r: number, g: number, b: number) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

// From rgb(231, 229, 246) to [0.9058823529411765,0.8980392156862745,0.9647058823529412,1]
export const rgbStringToRgbaString = (rgbString: string) => {
  const result = rgbString.match(/rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\s*\)/);
  if (!result) {
    throw new Error("Invalid rgb color format");
  }
  const r = parseInt(result[1], 10) / 255;
  const g = parseInt(result[2], 10) / 255;
  const b = parseInt(result[3], 10) / 255;
  return `[${r},${g},${b},1]`;
};
