import { Ref, RefObject, useCallback } from "react";

import { RefCallBack } from "react-hook-form";

type InputRef = Ref<unknown> | undefined;

export type CurrentRef =
  | RefObject<HTMLInputElement | HTMLTextAreaElement | null>
  | HTMLInputElement
  | HTMLTextAreaElement
  | null;

export const useFormInputRefs = ({
  inputRefCallBack,
  inputRef,
  inputRefs,
}: {
  inputRefCallBack?: RefCallBack;
  inputRef?: InputRef;
  inputRefs?: InputRef[];
}) => {
  const handleInputRef = useCallback((currentRef: CurrentRef, currentInputRef: InputRef) => {
    if (typeof currentInputRef === "function") {
      currentInputRef?.(currentRef);
    } else if (currentInputRef) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentInputRef.current = currentRef;
    }
  }, []);

  return useCallback(
    (currentRef: CurrentRef) => {
      inputRefCallBack?.(currentRef);
      handleInputRef(currentRef, inputRef);
      inputRefs?.forEach((inputRef) => {
        handleInputRef(currentRef, inputRef);
      });
    },
    [inputRefCallBack, handleInputRef, inputRef, inputRefs]
  );
};
