import { forwardRef, useCallback, useId } from "react";

import { CheckboxProps as MuiCheckboxProps } from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import classnames from "classnames";

import { useIsFormV2 } from "../../../features/FormV2/hooks/useIsFormV2";
import { useCurrentInputVibe } from "../../../hooks/useCurrentInputVibe";
import { useCustomInputProps } from "../../../hooks/useCustomInputProps";
import { Icon } from "../Icon";
import { OptionLabel } from "../OptionLabel";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyCheckboxClasses, StyledCheckbox } from "./styles";
import { BaseCheckboxProps } from "./types";

import { useCustomError } from "@/hooks/useCustomError";
import { Check, Minus } from "@/icons/outlined";

export const BaseCheckbox = forwardRef<HTMLButtonElement, BaseCheckboxProps>(
  (
    {
      alignContent,
      className,
      classes: externalClasses,
      disabled = false,
      vibe,
      error,
      errorMessage,
      fullWidth,
      helperText,
      indeterminate = false,
      inputProps,
      inputRef,
      label,
      onChange,
      optionLabelClasses = {},
      size = "medium",
      tooltipText,
      "data-test": dataTest,
      name,
      style,
      clickableLabel: clickableLabelProps,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const currentInputVibe = useCurrentInputVibe(vibe);
    const classes = useMergeClasses(zeffyCheckboxClasses, externalClasses);
    const isFormV2 = useIsFormV2();

    const handleChange = useCallback<NonNullable<MuiCheckboxProps["onChange"]>>(
      (event, checked) => {
        onChange?.(event, checked);
      },
      [onChange]
    );

    const currentError = useCustomError(error, errorMessage);

    const customInputProps = useCustomInputProps({
      inputProps,
      dataTest: "checkbox-input",
    });

    const clickableLabel = (clickableLabelProps ?? isFormV2) && !disabled;
    const inputId = useId();
    const realInputId = clickableLabel ? name || inputId : undefined;

    return (
      <OptionLabel
        alignContent={alignContent}
        className={className}
        classes={optionLabelClasses}
        vibe={currentInputVibe}
        data-test={dataTest}
        disabled={disabled}
        errorMessage={errorMessage}
        fullWidth={fullWidth}
        helperText={helperText}
        label={label}
        size={size}
        tooltipText={tooltipText}
        style={style}
        labelSx={{
          cursor: clickableLabel ? "pointer" : "default",
        }}
        inputId={realInputId}
      >
        <StyledCheckbox
          id={realInputId}
          classes={{
            root: classnames(classes.root, {
              [classes.small]: size === "small",
              [classes.formV2]: theme.constants.isFormV2,
              [classes.error]: error,
            }),
            disabled: classes.disabled,
          }}
          color="primary"
          checkedIcon={
            <div
              className={classnames(classes.icon, classes.filledIcon, {
                [classes.small]: size === "small",
              })}
            >
              <Icon vibe="inherit" size={size}>
                <Check />
              </Icon>
            </div>
          }
          disabled={disabled}
          icon={
            <div
              className={classnames(classes.icon, classes.emptyIcon, {
                [classes.iconForm]: currentInputVibe === "form",
                [classes.small]: size === "small",
                [classes.error]: currentError,
              })}
            />
          }
          indeterminate={indeterminate}
          indeterminateIcon={
            <div
              className={classnames(classes.icon, classes.filledIcon, {
                [classes.small]: size === "small",
              })}
            >
              <Icon vibe="inherit" size={size}>
                <Minus />
              </Icon>
            </div>
          }
          inputProps={customInputProps}
          inputRef={inputRef}
          onChange={handleChange}
          ref={ref}
          size={size}
          disableRipple
          name={name}
          {...rest}
        />
      </OptionLabel>
    );
  }
);

BaseCheckbox.displayName = "Checkbox";
