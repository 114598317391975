import { forwardRef, useCallback, Ref, RefAttributes, useEffect, useRef, type JSX } from "react";

import { FieldValues, Path, PathValue, useController, useWatch } from "react-hook-form";

import { getLabel, isInputRequired } from "../../../helpers/validators";

import { BaseCheckbox } from "./BaseCheckBox";
import { BaseCheckboxProps, FormCheckboxProps } from "./types";

import { useFormInputRefs } from "@/hooks/useFormInputRefs";
import { useValidators } from "@/hooks/useValidators";

function FormCheckboxInner<T extends FieldValues>(
  {
    blockFormOnChange,
    control,
    defaultChecked,
    name,
    onBlur,
    onChange,
    rules,
    noAsterisk,
    ...rest
  }: FormCheckboxProps<T>,
  ref: Ref<HTMLButtonElement>
) {
  const elementRef = useRef<HTMLElement | null>(null);
  const validators = useValidators(rules);
  const isRequired = isInputRequired(validators);
  const watchedValue = useWatch({ control, name });
  const currentDefaultChecked = watchedValue ?? (defaultChecked as PathValue<T, Path<T>>);

  const { field } = useController<T>({
    name,
    control,
    defaultValue: currentDefaultChecked,
    rules: validators,
  });

  const handleChange = useCallback<NonNullable<BaseCheckboxProps["onChange"]>>(
    (event, value) => {
      if (!blockFormOnChange) {
        field.onChange(value);
      }
      onChange?.(event, value);
    },
    [blockFormOnChange, field, onChange]
  );

  const handleBlur = useCallback<NonNullable<BaseCheckboxProps["onBlur"]>>(
    (event) => {
      field.onBlur();
      onBlur?.(event);
    },
    [field, onBlur]
  );

  const refCallBack = useFormInputRefs({
    inputRefCallBack: (element: HTMLInputElement | null) => {
      field.ref(element);
      elementRef.current = element;
    },
  });

  /* Workaround to scroll checkbox element into view when focused on iOS */
  useEffect(() => {
    const element = elementRef?.current;

    if (!element) {
      return;
    }

    let wasClicked = false;

    const handlePress = () => {
      wasClicked = true;
    };

    const handleFocus = () => {
      if (!wasClicked) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      // Reset the flag after handling focus
      wasClicked = false;
    };

    element.addEventListener("mousedown", handlePress);
    element.addEventListener("touchstart", handlePress);
    element.addEventListener("focus", handleFocus);

    return () => {
      element.removeEventListener("mousedown", handlePress);
      element.removeEventListener("touchstart", handlePress);
      element.removeEventListener("focus", handleFocus);
    };
  }, []);

  return (
    <BaseCheckbox
      onBlur={handleBlur}
      onChange={handleChange}
      {...rest}
      ref={ref}
      inputRef={refCallBack}
      checked={Boolean(field.value)}
      value={field.value}
      label={getLabel(isRequired, rest.label, noAsterisk)}
      name={name}
    />
  );
}

FormCheckboxInner.displayName = "FormCheckbox";

export const FormCheckbox = forwardRef(FormCheckboxInner) as unknown as <T extends FieldValues>(
  props: FormCheckboxProps<T> & RefAttributes<HTMLButtonElement>
) => JSX.Element;
