import { memo } from "react";

import MuiPopper from "@mui/material/Popper";
import { Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { InternalClasses } from "../helpers";

import { TooltipBaseClasses, TooltipVibe } from "./types";

const PREFIX = "ZeffyTooltip";

export const zeffyTooltipClasses: InternalClasses<typeof PREFIX, TooltipBaseClasses> = {
  arrow: `${PREFIX}-arrow`,
  tooltip: `${PREFIX}-tooltip`,
  brand: `${PREFIX}-brand`,
  neutral: `${PREFIX}-neutral`,
  positive: `${PREFIX}-positive`,
};

const getVibeStyles = (theme: Theme, vibe: TooltipVibe): CSSProperties => {
  const surfaceVibe = theme.palette.surface[vibe].intense;
  const textVibe = theme.palette.text.reverse.intense;
  return {
    backgroundColor: surfaceVibe,
    boxShadow: theme.boxShadow.palette(surfaceVibe).mid,
    color: textVibe,
    fill: textVibe,
    [`& .${zeffyTooltipClasses.arrow}`]: {
      color: surfaceVibe,
    },
  };
};

export const StyledPopper = memo(
  styled(MuiPopper)(({ theme }: { theme: Theme }) => ({
    [`& .${zeffyTooltipClasses.tooltip}`]: {
      borderRadius: theme.radius(1),
      padding: theme.spacing(0.75, 1),
      textAlign: "start",
    },

    [`& .${zeffyTooltipClasses.brand}`]: getVibeStyles(theme, "brand"),
    [`& .${zeffyTooltipClasses.neutral}`]: getVibeStyles(theme, "neutral"),
    [`& .${zeffyTooltipClasses.positive}`]: getVibeStyles(theme, "positive"),
  }))
);
