import { ReactNode } from "react";

import { CountryCode, isEINValid as isEINValidCommon } from "@simplyk/common";
import { phone } from "phone";
import { RegisterOptions, ValidationRule } from "react-hook-form";
import { isURL, isEmail as validatorIsEmail } from "validator";

export const isRequired = (
  valueOrMessage: string | boolean = "common.isRequired",
  message?: string
): RegisterOptions => {
  if (typeof valueOrMessage === "boolean") {
    return {
      required: {
        value: valueOrMessage,
        message: message as string,
      },
    };
  }
  return {
    required: {
      value: true,
      message: valueOrMessage as string,
    },
  };
};

export const isInputRequired = (
  validators?: { required: boolean } | { required: string | ValidationRule<boolean> | undefined }
) => {
  if (typeof validators?.required === "boolean") {
    return Boolean(validators?.required);
  } else {
    if (typeof validators?.required === "string") {
      return true;
    }
    return Boolean(validators?.required?.value);
  }
};

export const isCCode = (message: string): RegisterOptions => ({
  validate: {
    isCCode: (value: number) => {
      if (
        ![2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 25, 26, 27, 28, 92].includes(
          value
        )
      ) {
        return message;
      }
      return true;
    },
  },
});

export const isEINValid = (): RegisterOptions => ({
  validate: {
    isValidFormat: (value: string) => {
      if (!value) {
        return true;
      }
      const isEINValidValue = isEINValidCommon(value);
      if (!isEINValidValue) {
        return "signup.EINNotValid";
      }

      return true;
    },
  },
});

export const isMin = (min: number, message?: string): RegisterOptions => ({
  validate: {
    isMin: (value: string | number | undefined) => {
      // Bypass validation only for undefined/null/empty string
      if (value === undefined || value === null || value === "") {
        return true;
      }

      const numValue = Number(value);
      // Validate all numbers including 0
      return !isNaN(numValue) && numValue >= min ? true : message || false;
    },
  },
});

export const isMax = (max: number, message?: string): RegisterOptions => ({
  validate: {
    isMax: (value: string | number | undefined) => {
      // Bypass validation only for undefined/null/empty string
      if (value === undefined || value === null || value === "") {
        return true;
      }

      const numValue = Number(value);
      // Validate all numbers including 0
      return !isNaN(numValue) && numValue <= max ? true : message || false;
    },
  },
});

export const isListOfEmails = (message: string): RegisterOptions => ({
  validate: {
    listOfEmails: (value?: string) => {
      const emails = value?.split(",").map((email) => email.trim());
      const allEmailValid = emails?.every((email) => {
        return validatorIsEmail(email);
      });

      if ((value?.length || 0) > 0 && !allEmailValid) {
        return message;
      }
      return true;
    },
  },
});

export const isDate = (message: string): RegisterOptions => ({
  validate: {
    date: (value: string) => {
      if (!value) {
        return true;
      }

      if (!isNaN(Date.parse(value)) || !isNaN(parseInt(value))) {
        return true;
      }

      return message;
    },
  },
});

export const isEmail = (invalidEmailMessage: string, disallowedCharMessage: string): RegisterOptions => ({
  validate: {
    email: (value?: string) => {
      if (!value) {
        return true;
      }

      const isEmail = validatorIsEmail(value);
      const disallowedCharRegex = /[^a-zA-Z0-9_.\-@]/; // (+ is not accepted here)
      if (value?.length > 0 && disallowedCharRegex.test(value)) {
        return disallowedCharMessage;
      } else if (value?.length > 0 && !isEmail) {
        return invalidEmailMessage;
      }
      return true;
    },
  },
});

export const isEmailAndAllowPlus = (invalidEmailMessage: string, disallowedCharMessage: string): RegisterOptions => ({
  validate: {
    email: (value: string) => {
      const disallowedCharRegex = /[^a-zA-Z0-9_.+\-@]/; // (+ is accepted here)
      if (value?.length > 0) {
        if (disallowedCharRegex.test(value)) {
          return disallowedCharMessage;
        }
        if (!validatorIsEmail(value)) {
          return invalidEmailMessage;
        }
      }
      return true;
    },
  },
});

export const isYoutubeVideo = (message: string): RegisterOptions => ({
  validate: {
    url: (value: string) => {
      if (value && !value.includes("youtube") && !value.includes("youtu.be")) {
        return message;
      }
      return true;
    },
  },
});

export const isTicketSelectionValid = (value: {
  extraDonation?: number | null;
  ticketsPurchased?: { purchasedNumber?: number }[];
}): boolean => {
  return Boolean(
    value.extraDonation ||
      value.ticketsPurchased?.some((rate) => {
        return Boolean(rate.purchasedNumber && rate.purchasedNumber > 0);
      })
  );
};

export const isMinOrUnset = (min: number, message: string): RegisterOptions => ({
  validate: {
    minOrUnset: (value: number) => {
      if (value !== 0 && !value) {
        return true;
      }
      if (value >= min) {
        return true;
      }
      return message;
    },
  },
});

export const isMinOrZero = (min: number, message: string): RegisterOptions => ({
  validate: {
    minOrZero: (value: number) => {
      if (value === 0) {
        return true;
      }
      if (value >= min) {
        return true;
      }
      return message;
    },
  },
});

export const isZero = (message: string): RegisterOptions => ({
  validate: {
    isZero: (value: number) => {
      if (value === 0) {
        return message;
      }
      return true;
    },
  },
});

export const multiSelectFilled = (message: string): RegisterOptions => ({
  validate: {
    multiSelectFilled: (value: string[]) => {
      if (value && value.length > 0) {
        return true;
      }
      return message;
    },
  },
});

export const isPassword = (message: string): RegisterOptions => ({
  validate: {
    isPassword: (value: string) => {
      const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
      if (value?.length > 0 && !regex.test(value)) {
        return message;
      }
      return true;
    },
  },
});

export const isWebsiteValid = (message: string): RegisterOptions => ({
  validate: {
    websiteUrl: (value?: string) => {
      const isUrl = value && isURL(value, { require_host: true });
      if (value && value?.length > 0 && !isUrl) {
        return message;
      }
      if (value?.includes("@")) {
        // detect when user enter its emails instead of website (stripe verification does not allow websites)
        return message;
      }
      return true;
    },
  },
});

export const isPhoneNumberValid = (message: string, countryCode?: CountryCode): RegisterOptions => ({
  validate: {
    phoneNumber: (value: string | undefined) => {
      if (value && value?.length > 0) {
        const { isValid } = phone(`+${value}`, { country: countryCode });
        if (!isValid) {
          return message;
        }
      }
      return true;
    },
  },
});

export const isLaterThan = (earlierDate?: Date | null, message?: string): RegisterOptions => ({
  validate: {
    isLater: (laterDate?: Date) => {
      if (!earlierDate || !laterDate || earlierDate < laterDate) {
        return true;
      }
      return message;
    },
  },
});

export const getLabel = (
  isRequired: boolean | undefined,
  label: string | undefined | null | ReactNode,
  noAsterisk?: boolean
) => {
  return isRequired && label && typeof label === "string" && !noAsterisk ? label + "*" : label;
};

export const isUsaDateOfBirth = (message: string): RegisterOptions => ({
  validate: {
    isUsaDateOfBirth: (value?: string) => {
      const regex = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
      if (value && value?.length > 0 && !regex.test(value)) {
        return message;
      }
      return true;
    },
  },
});

export const containsOnlyLatinCharactersOrPunctuation = (message: string) => ({
  validate: {
    containsOnlyLatinCharacters: (value?: string) => {
      const regex = /^[A-Za-z0-9\s.,!?;:'"()@#%&*-]+$/;
      if (value && value?.length > 0 && !regex.test(value)) {
        return message;
      }
      return true;
    },
  },
});

export const isLengthBetween = ({ min, max }: { min: number; max: number }, message: string) => ({
  validate: {
    isLengthBetween: (value?: string) => {
      const isBetween = value && value?.length >= min && value?.length <= max;
      if (value && value?.length > 0 && !isBetween) {
        return message;
      }
      return true;
    },
  },
});

export const containsAtLeastOneLetter = (message: string) => ({
  validate: {
    containsAtLeastOneLetter: (value?: string) => {
      const regex = /[A-Za-z]/;
      if (value && value?.length > 0 && !regex.test(value)) {
        return message;
      }
      return true;
    },
  },
});

export const doesNotContainSpecialCharacters = (message: string) => ({
  validate: {
    doesNotContainSpecialCharacters: (value?: string) => {
      const regex = /^[^<>\\'"*]+$/;
      if (value && value?.length > 0 && !regex.test(value)) {
        return message;
      }
      return true;
    },
  },
});

export const isNbctcReferralCodeValid = (errorMessage: string) => (value: string) => {
  const isValid = /^[A-Z]{5,6}\d{3}$/.test(value);
  return isValid || errorMessage;
};

export const isRoutingNumberLengthValid = (message: string): RegisterOptions => ({
  validate: {
    isRoutingNumberLengthValid: (value?: string) => {
      if (!value) {
        return true;
      }
      return value.length === 9 || message;
    },
  },
});

export const isAccountNumberLengthValid = (message: string): RegisterOptions => ({
  validate: {
    isAccountNumberLengthValid: (value?: string) => {
      if (!value) {
        return true;
      }
      return (value.length >= 6 && value.length <= 17) || message;
    },
  },
});

export const hasOnlyNumbers = (message: string): RegisterOptions => ({
  validate: {
    hasOnlyNumbers: (value?: string) => {
      if (!value) {
        return true;
      }
      return /^\d+$/.test(value) || message;
    },
  },
});
