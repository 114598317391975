import { useCallback } from "react";

// eslint-disable-next-line no-restricted-imports
import { useTranslation } from "next-i18next";
import { TransProps as I18NTransProps, Trans as I18nTrans } from "react-i18next";

import { Translations } from "../../public/@types/translations";

export type Translate = <T extends keyof Translations>(
  space: T,
  key: keyof Translations[T] & string,
  params?: Record<string, string | number | undefined | null>
) => string;

type TransProps<T extends keyof Translations> = Omit<I18NTransProps<T>, "i18nKey" | "ns"> & {
  space: T;
  i18nKey: keyof Translations[T] & string;
};

function buildI18NKey<T extends keyof Translations>(space: T, key: keyof Translations[T] & string) {
  return `${space}:${key}`;
}

export const Trans = <T extends keyof Translations>({ space, i18nKey, ...rest }: TransProps<T>) => {
  return I18nTrans({ ...rest, i18nKey: buildI18NKey(space, i18nKey) });
};

export const useTranslate = () => {
  const { t: i18nTranslation, i18n } = useTranslation();

  const t: Translate = useCallback(
    (space, key, params) => {
      return i18nTranslation(buildI18NKey(space, key), params || {});
    },
    [i18nTranslation]
  );

  return { t, i18n };
};
