import { useMemo } from "react";

import { InputBaseComponentProps } from "@mui/material/InputBase";

export type CustromInputHookProps = {
  inputProps?: InputBaseComponentProps;
  dataTest?: string;
  disableAutocomplete?: boolean;
  extraProps?: InputBaseComponentProps;
};

export const useCustomInputProps = ({
  inputProps,
  dataTest,
  disableAutocomplete,
  extraProps,
}: CustromInputHookProps): InputBaseComponentProps => {
  return useMemo<InputBaseComponentProps>(
    () => ({
      ...inputProps,
      ...extraProps,
      ...(dataTest ? { "data-test": dataTest } : {}),
      // Should be `autoComplete: "off"`, but chrome currently does not respect it
      // https://bugs.chromium.org/p/chromium/issues/detail?id=587466
      // Following this recommendation
      // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
      ...(disableAutocomplete && { autoComplete: "new-password" }),
    }),
    [dataTest, disableAutocomplete, extraProps, inputProps]
  );
};
