import { forwardRef, Ref, RefAttributes, type JSX } from "react";

import { FieldValues } from "react-hook-form";

import { BaseCheckbox } from "./BaseCheckBox";
import { FormCheckbox } from "./FormCheckbox";
import { CheckboxProps } from "./types";

function CheckboxInner<T extends FieldValues>(
  { control, name, ...rest }: CheckboxProps<T>,
  ref: Ref<HTMLButtonElement>
) {
  return control && name ? (
    <FormCheckbox control={control} name={name} {...rest} ref={ref} />
  ) : (
    <BaseCheckbox {...rest} name={name} ref={ref} />
  );
}

CheckboxInner.displayName = "Checkbox";

export const Checkbox = forwardRef(CheckboxInner) as <T extends FieldValues>(
  props: CheckboxProps<T> & RefAttributes<HTMLButtonElement>
) => JSX.Element;
