import * as React from "react";

import { FieldValues, RegisterOptions, UseControllerProps, Validate } from "react-hook-form";

export const useValidators = (rules?: RegisterOptions[]): UseControllerProps["rules"] => {
  return React.useMemo(() => {
    if (!rules) {
      return {};
    }
    const output = rules.reduce(
      (rule, item) => {
        const { validate, ...rest } = item;
        if (!validate) {
          return { ...rule, ...rest };
        }
        return rule;
      },
      {} as Omit<RegisterOptions<FieldValues, string>, "register">
    );
    output.validate = rules.reduce(
      (rule, item) => {
        if (item.validate) {
          return { ...rule, ...item.validate };
        }
        return rule;
      },
      {} as Record<string, Validate<unknown, FieldValues>>
    );

    return output;
  }, [rules]);
};
