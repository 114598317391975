/* eslint-disable sonarjs/no-duplicate-string */
import { memo } from "react";

import { styled, Theme } from "@mui/material/styles";

export type OptionLabelBaseClasses =
  | "container"
  | "contentSection"
  | "contentSectionCentered"
  | "children"
  | "childrenSmall"
  | "labelSection"
  | "label"
  | "labelNeutral"
  | "labelForm"
  | "disabledLabel"
  | "tooltip"
  | "tooltipSmall"
  | "error"
  | "helperText"
  | "fullWidth"
  | "clickable"; // Added "clickable" to the base classes

const PREFIX = "OptionLabel";

export const zeffyOptionLabelClasses = {
  container: `${PREFIX}-container`,
  contentSection: `${PREFIX}-contentSection`,
  contentSectionCentered: `${PREFIX}-contentSectionCentered`,
  children: `${PREFIX}-children`,
  childrenSmall: `${PREFIX}-childrenSmall`,
  labelSection: `${PREFIX}-labelSection`,
  label: `${PREFIX}-label`,
  labelNeutral: `${PREFIX}-labelNeutral`,
  labelForm: `${PREFIX}-labelForm`,
  tooltip: `${PREFIX}-tooltip`,
  tooltipSmall: `${PREFIX}-tooltipSmall`,
  disabledLabel: `${PREFIX}-disabledLabel`,
  error: `${PREFIX}-error`,
  helperText: `${PREFIX}-helperText`,
  fullWidth: `${PREFIX}-fullWidth`,
  clickable: `${PREFIX}-clickable`, // Added "clickable" class
};

export const StyledRoot = memo(
  styled("div")(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyOptionLabelClasses.container}`]: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0.25, 0),
      justifyContent: "center",
    },

    [`& .${zeffyOptionLabelClasses.contentSection}`]: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      display: "inline-flex",
      justifyContent: "flex-start",
    },

    [`& .${zeffyOptionLabelClasses.contentSectionCentered}`]: {
      alignItems: "center",
    },

    [`& .${zeffyOptionLabelClasses.children}`]: {
      alignItems: "center",
      display: "inline-flex",
      height: "fit-content",
      minHeight: theme.typography.body1.lineHeight,
    },

    [`& .${zeffyOptionLabelClasses.childrenSmall}`]: {
      minHeight: theme.typography.body2.lineHeight,
    },

    [`& .${zeffyOptionLabelClasses.labelSection}`]: {
      marginLeft: theme.spacing(1),
      display: "inline-flex",
    },

    [`& .${zeffyOptionLabelClasses.label}`]: {},

    [`& .${zeffyOptionLabelClasses.labelNeutral}`]: {
      color: theme.palette.text.neutral.moderate,
    },

    [`& .${zeffyOptionLabelClasses.labelForm}`]: {
      color: theme.palette.text.form.moderate,
    },

    [`&& .${zeffyOptionLabelClasses.tooltip}`]: {
      marginLeft: theme.spacing(0.5),
      display: "inline-block",
      height: theme.typography.body1.lineHeight,
    },

    [`& .${zeffyOptionLabelClasses.tooltipSmall}`]: {
      height: theme.typography.body2.lineHeight,
    },

    [`& .${zeffyOptionLabelClasses.disabledLabel}`]: {
      opacity: 0.4,
    },

    [`& .${zeffyOptionLabelClasses.error}`]: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.danger.quiet,
    },

    [`& .${zeffyOptionLabelClasses.helperText}`]: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.neutral.quiet,
    },

    [`&.${zeffyOptionLabelClasses.fullWidth}, .${zeffyOptionLabelClasses.fullWidth}`]: {
      width: "100%",
    },

    [`&.${zeffyOptionLabelClasses.clickable}, & .${zeffyOptionLabelClasses.clickable}`]: {
      cursor: "pointer",
    },
  }))
);
