import { Theme } from "@mui/material/styles";
import useMuiMediaQuery, { UseMediaQueryOptions } from "@mui/material/useMediaQuery";

import { useIframeWindowContext } from "../features/LiveFormEditor/LivePreview/context/IframeWindowContext";

export const useMediaQuery = (query?: string | ((theme: Theme) => string), options?: UseMediaQueryOptions) => {
  const value = useMuiMediaQuery(query || "", options);

  const iframeWindow = useIframeWindowContext();
  const windowOptions = iframeWindow ? { matchMedia: iframeWindow.matchMedia } : {};

  const isPhoneScreen = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("sm"), windowOptions);
  const isSmallScreen = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("md"), windowOptions);
  const isMediumScreen = useMuiMediaQuery((theme: Theme) => theme.breakpoints.down("lg"), windowOptions);
  const isLargeScreen = useMuiMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), windowOptions);

  return { value, isPhoneScreen, isSmallScreen, isLargeScreen, isMediumScreen };
};
