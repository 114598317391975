import React, { createContext, useContext } from "react";

export const IframeWindowContext = createContext<Window | undefined>(undefined);

export const IframeWindowProvider: React.FC<{ window?: Window; children: React.ReactNode }> = ({
  children,
  window,
}) => {
  return <IframeWindowContext.Provider value={window}>{children}</IframeWindowContext.Provider>;
};

export const useIframeWindowContext = () => {
  const iframeWindow = useContext(IframeWindowContext);
  return iframeWindow || (typeof window !== "undefined" ? window : undefined);
};
