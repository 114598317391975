import { Locales, CadIsoLocale, UsaIsoLocale, UsaLocales, Day } from "../enums";
import { OrganizationCountry } from "../types/countryRegion";
export const allowedLocales = Object.keys(Locales);
// TODO: create FieldBase type to extend all Field types
export const getField = (fields, locale) => {
    return fields?.find((field) => field.locale === locale) || fields?.[0];
};
export const localeToCadIsoLocale = (locale) => locale === Locales.FR ? CadIsoLocale.FR : CadIsoLocale.EN;
export const localeToUsaIsoLocale = (locale) => locale === Locales.ES ? UsaIsoLocale.ES : UsaIsoLocale.EN;
export const getLocalesFromIsoLocale = (isoLocale) => isoLocale?.toLowerCase()?.includes("fr")
    ? Locales.FR
    : isoLocale?.toLowerCase()?.includes("es")
        ? Locales.ES
        : Locales.EN;
export const getIsoLocaleFromCountry = (country, storedLocale) => {
    if (country === OrganizationCountry.Canada) {
        return localeToCadIsoLocale(storedLocale) || CadIsoLocale.EN;
    }
    if (country === OrganizationCountry.UnitedStates) {
        return localeToUsaIsoLocale(storedLocale) || UsaIsoLocale.EN;
    }
    return localeToCadIsoLocale(storedLocale);
};
export const getLocaleFromCountry = (country, storedLocale) => {
    if (country === OrganizationCountry.Canada) {
        return storedLocale;
    }
    if (country === OrganizationCountry.UnitedStates) {
        return UsaLocales[storedLocale] || UsaLocales.EN;
    }
    return storedLocale;
};
export const getOppositeLocale = ({ organizationCountry, locale }) => {
    if (!organizationCountry || !locale) {
        return Locales.EN;
    }
    if (organizationCountry === OrganizationCountry.UnitedStates) {
        return locale === Locales.EN ? Locales.ES : Locales.EN;
    }
    return locale === Locales.FR ? Locales.EN : Locales.FR;
};
export const convertDayToLocale = (day, locale) => {
    const dayTranslationFr = {
        [Day.Monday]: "lundi",
        [Day.Tuesday]: "mardi",
        [Day.Wednesday]: "mercredi",
        [Day.Thursday]: "jeudi",
        [Day.Friday]: "vendredi",
        [Day.Saturday]: "samedi",
        [Day.Sunday]: "dimanche",
    };
    return locale === Locales.FR ? dayTranslationFr[day] : day;
};
