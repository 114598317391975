import * as React from "react";
import { useContext } from "react";

import { IsoLocale, Locales, UsaIsoLocale, getIsoLocaleFromCountry, getLocalesFromIsoLocale } from "@simplyk/common";
import { useRouter } from "next/router";

import { useSetIsoLocale } from "../hooks/useSetIsoLocale";

import { useCurrentUserContext } from "./CurrentUserContext";

interface LocaleContextProps {
  isoLocale: IsoLocale;
  setIsoLocale: (locale: IsoLocale) => void;
  locale: Locales;
}

export const LocaleContext = React.createContext<LocaleContextProps>({
  isoLocale: UsaIsoLocale.EN,
  setIsoLocale: () => {},
  locale: Locales.EN,
});

interface LocaleProviderProps {
  children: React.ReactElement<unknown>;
  skipUserLocaleRedirection?: boolean;
}

export const useLocaleContext = (): LocaleContextProps => {
  return useContext(LocaleContext);
};

export const LocaleProvider: React.FunctionComponent<React.PropsWithChildren<LocaleProviderProps>> = ({
  children,
  skipUserLocaleRedirection,
}) => {
  const router = useRouter();
  const { locale: isoLocale } = router as { locale: IsoLocale };
  const { currentUser, organization } = useCurrentUserContext();

  const { setIsoLocale } = useSetIsoLocale();

  const locale = getLocalesFromIsoLocale(isoLocale);
  React.useEffect(() => {
    if (skipUserLocaleRedirection) {
      return;
    }
    if (currentUser?.locale) {
      const userIsoLocale = getIsoLocaleFromCountry(organization?.country, currentUser.locale);
      if (userIsoLocale && isoLocale !== userIsoLocale) {
        setIsoLocale(userIsoLocale);
      }
    }
  }, [currentUser?.locale, isoLocale, organization?.country, setIsoLocale, skipUserLocaleRedirection]);

  return (
    <LocaleContext.Provider
      value={{
        isoLocale,
        setIsoLocale,
        locale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
