import { memo } from "react";

import MuiCheckbox from "@mui/material/Checkbox";
import { styled, Theme } from "@mui/material/styles";

import { InternalClasses } from "../helpers";

export type CheckboxBaseClasses =
  | "disabled"
  | "error"
  | "icon"
  | "iconForm"
  | "emptyIcon"
  | "filledIcon"
  | "root"
  | "small"
  | "formV2";

const PREFIX = "ZeffyCheckbox";

export const zeffyCheckboxClasses: InternalClasses<typeof PREFIX, CheckboxBaseClasses> = {
  icon: `${PREFIX}-icon`,
  emptyIcon: `${PREFIX}-emptyIcon`,
  iconForm: `${PREFIX}-iconForm`,
  filledIcon: `${PREFIX}-filledIcon`,
  error: `${PREFIX}-error`,
  disabled: `${PREFIX}-disabled`,
  root: `${PREFIX}-root`,
  small: `${PREFIX}-small`,
  formV2: `${PREFIX}-formV2`,
};

export const StyledCheckbox = memo(
  styled(MuiCheckbox)(({ theme }: { theme: Theme }) => ({
    [`& .${zeffyCheckboxClasses.icon}`]: {
      borderRadius: theme.radius(0.5),
      display: "flex",
      height: 20,
      width: 20,
    },

    [`& .${zeffyCheckboxClasses.emptyIcon}`]: {
      border: `1px solid ${theme.palette.border.neutral.intense}`,
    },

    [`&.${zeffyCheckboxClasses.formV2}`]: {
      [`& .${zeffyCheckboxClasses.icon}`]: {
        borderColor: `${theme.palette.text.form.moderate}`,
        accentColor: `${theme.palette.text.form.moderate}`,
      },
    },

    [`& .${zeffyCheckboxClasses.filledIcon}`]: {
      backgroundColor: theme.palette.surface.brand.intense,
      color: theme.palette.text.reverse.intense,
      fill: theme.palette.text.reverse.intense,
    },

    [`&.${zeffyCheckboxClasses.error}`]: {
      borderColor: theme.palette.border.danger.intense,
      [`& .${zeffyCheckboxClasses.icon}`]: {
        borderColor: theme.palette.border.danger.intense,
      },
    },

    [`&.${zeffyCheckboxClasses.disabled}`]: {
      opacity: 0.4,
      cursor: "not-allowed",
    },

    [`&.${zeffyCheckboxClasses.root}`]: {
      height: 20,
      width: 20,
      padding: 0,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "unset",
        [`& .${zeffyCheckboxClasses.icon}`]: {
          borderColor: theme.palette.border.brand.intense,
        },
      },
      "&:focus, &:focus-visible, &:focus-within": {
        [`& .${zeffyCheckboxClasses.icon}`]: {
          borderColor: theme.palette.border.brand.intense,
          outline: `2px solid ${theme.palette.border.brand.quiet}`,
        },
      },
      [`&.${zeffyCheckboxClasses.disabled}`]: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
    },

    [`& .${zeffyCheckboxClasses.small}, &.${zeffyCheckboxClasses.small}`]: {
      height: 16,
      width: 16,
    },
  }))
);
